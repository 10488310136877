import { Component, ViewContainerRef, OnInit, OnDestroy,   PLATFORM_ID,
    Inject, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router,  @Inject(PLATFORM_ID) private platformId: Object, private translate: TranslateService) {

        if (isPlatformBrowser(this.platformId)) {
            translate.addLangs(["en", "bg", "ru"]);
      
            var language = localStorage.getItem("language");
      
            if (!language) {
              const browserLang = translate.getBrowserLang();
              translate.use(browserLang.match(/en|bg|ru/) ? browserLang : "bg");
      
              translate.currentLang = browserLang;
      
              localStorage.setItem("language", browserLang);
            } else {
              translate.use(language);
              translate.currentLang = language;
              
              localStorage.setItem("language", language);
            }
          }
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}